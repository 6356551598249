<template>
  <div class="my-okr custom-tableview">
    <b-card no-body class="mb-0 mt-2 py-3">
      <!-- Table Top -->
      <b-row class="mx-0 align-items-center px-50 top-search">
        <!-- Search -->
        <b-col cols="12" md="6">
          <div class="d-flex align-items-center pl-0 filter-data">
            <feather-icon class="cursor-pointer border round p-50 accordion-button collapsed" icon="SlidersIcon"
              size="32" v-b-toggle.filter-collapse />
            <div class="d-flex align-items-center justify-content-end search-okr">
              <b-input-group class="input-group-merge round w-100">
                <b-form-input v-model="filters.searchQuery" class="d-inline-block" placeholder="Search by Name or OKR Title" />
              </b-input-group>
            </div>
          </div>
        </b-col>
        <b-col cols="12" md="6" class="d-flex align-items-center justify-content-end mobile-align">
          <!-- <b-dropdown
      id="dropdown-1"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
    ><template #button-content>
      <feather-icon
        icon="PlusIcon"
        size="16"
        class="align-middle"
      />
      <span> Add OKR</span>      
    </template>
<b-dropdown-item :to="{
        name: 'create-okr',
        params: { type: 'individual-okr'},
      }"><feather-icon icon="PlusIcon" class="ml-1" /> Individual OKR</b-dropdown-item>
<b-dropdown-item :to="{
        name: 'create-okr',
        params: { type: 'team-okr'},
      }"><feather-icon icon="PlusIcon" class="ml-1" />Team OKR</b-dropdown-item>
<b-dropdown-item :to="{
        name: 'create-okr',
        params: { type: 'organisation-okr'},
      }"><feather-icon icon="PlusIcon" class="ml-1" />Organistion OKR</b-dropdown-item>
</b-dropdown> -->
          <b-dropdown v-if="pageType != 'review-okrs'" :text="filters.typeFilter+' OKR'" class="custom-dropdown" variant="outline-secondary">
            <b-dropdown-item @click="switchTo(okrtype)" v-for="okrtype in typeOptions">{{ okrtype }} OKR</b-dropdown-item>
          </b-dropdown>
          <div v-if="pageType != 'review-okrs'" >
            <b-link class="btn btn-primary rounded-pill ml-1" v-if="!filters.typeFilter || filters.typeFilter == 'Individual'" :to="{
              name: 'create-okr',
              params: { type: 'individual-okr' },
            }"><feather-icon icon="PlusIcon" /> Add New OKR</b-link>
            <b-link class="btn btn-primary rounded-pill ml-1" v-if="filters.typeFilter == 'Team' && ($can('Zircly Admin') || $can('reporting_manager'))" :to="{
              name: 'create-okr',
              params: { type: 'team-okr' },
            }"><feather-icon icon="PlusIcon" /> Add Team OKR</b-link>
            <b-link class="btn btn-primary rounded-pill ml-1" v-if="filters.typeFilter == 'Organisation' && ($can('Zircly Admin') || $can('Organisation OKR Manager'))" :to="{
              name: 'create-okr',
              params: { type: 'organisation-okr' },
            }"><feather-icon icon="PlusIcon" /> Add Organisation OKR</b-link>
          </div>
        </b-col>
      </b-row>

      <b-collapse id="filter-collapse" class="mt-2">
        <div class="mx-0 px-2 filter-okr">
          <div v-if="pageType == 'all-okrs'" :class="(filters.managerFilter.length) ? 'mb-md-0 mb-2 manager-list hasValue' : 'mb-md-0 mb-2 manager-list'">
            <b-dropdown id="dropdown-aria-manager" :text="(filters.managerFilter.length)?'Reporting Manager ('+filters.managerFilter.length+')':'Reporting Manager'" variant="outline-secondary"
              class="custom-multiselect" checkbox-menu allow-focus>
              <div class="header-search px-2 my-25">
                <div class="d-flex align-items-center justify-content-end search-okr">
                  <b-input-group class="input-group-merge round w-100">
                    <b-form-input v-model="searchManager" class="d-inline-block"
                      placeholder="Search by Name, Email of Manager" />
                  </b-input-group>
                </div>
              </div>
              <b-dropdown-form>
                <b-form-checkbox :id="'manager-'+manager.hashid" :name="'manager-'+manager.hashid" :value="manager.email" 
                v-model="selectedManagers" v-for="manager in filteredManagerOptions">
                  <div class="manager-info">
                    <h6 class="mb-0">{{manager.name}}</h6>
                    <p class="mb-0">{{manager.email}}</p>
                  </div>
                </b-form-checkbox>
              </b-dropdown-form>
              <b-dropdown-item-button class="footer-filter">
                <b-button variant="primary" pill class="w-100" @click="filters.managerFilter = selectedManagers"> Apply Filter</b-button>
              </b-dropdown-item-button>

            </b-dropdown>
          </div>
          <div v-if="pageType != 'my-okrs'" :class="(filters.employeeFilter.length) ? 'mb-md-0 mb-2 employee-list hasValue' : 'mb-md-0 mb-2 employee-list'">
            <b-dropdown id="dropdown-aria-employee" :text="(filters.employeeFilter.length)?'Employee ('+filters.employeeFilter.length+')':'Employee'" variant="outline-secondary"
              class="custom-multiselect" checkbox-menu allow-focus>
              <div class="header-search px-2 my-25">
                <div class="d-flex align-items-center justify-content-end search-okr">
                  <b-input-group class="input-group-merge round w-100">
                    <b-form-input v-model="searchEmployee" class="d-inline-block"
                      placeholder="Search by Name, Email of Employee" />
                  </b-input-group>
                </div>
              </div>
              <b-dropdown-form>
                <b-form-checkbox  :id="'employee-'+employee.hashid" :name="'employee-'+employee.hashid" :value="employee.email" 
                v-model="selectedEmployees" v-for="employee in filteredEmployeeOptions">
                  <div class="manager-info">
                    <h6 class="mb-0">{{employee.name}}</h6>
                    <p class="mb-0">{{employee.email}}</p>
                  </div>
                </b-form-checkbox>
              </b-dropdown-form>
              <b-dropdown-item-button class="footer-filter">
                <b-button variant="primary" pill class="w-100" @click="filters.employeeFilter = selectedEmployees"> Apply Filter</b-button>
              </b-dropdown-item-button>

            </b-dropdown>
          </div>
          <div :class="(filters.statusFilter) ? 'mb-md-0 mb-2 status-list hasValue' : 'mb-md-0 mb-2 status-list'" v-if="pageType != 'review-okrs'">
            <b-dropdown variant="outline-secondary" :text="filters.statusFilter ? filters.statusFilter : 'Status' " placeholder="Status" class="custom-multiselect">
              <b-dropdown-item @click="filters.statusFilter = status"
                v-for="status in statusOptions">{{ status }}</b-dropdown-item>
            </b-dropdown>
          </div>
          <!-- <div v-if="this.pageType == 'review-okrs'" :class="(filters.reviewStatusFilter) ? 'mb-md-0 mb-2 hasValue' : 'mb-md-0 mb-2'">
            <b-dropdown variant="outline-secondary" :text="filters.reviewStatusFilter ? filters.reviewStatusFilter : 'Approval Status' " placeholder="Status" class="custom-multiselect">
              <b-dropdown-item @click="filters.reviewStatusFilter = status"
                v-for="status in reviewStatusOptions">{{ status }}</b-dropdown-item>
            </b-dropdown>
          </div> -->
          <div :class="(filters.teamFilter) ? 'mb-md-0 mb-2 team-list hasValue' : 'mb-md-0 mb-2 team-list'"  v-if="this.pageType != 'review-okrs'" >
            <b-dropdown :text="filters.teamFilter ? filters.teamFilter.label : 'Team' " variant="outline-secondary" class="custom-multiselect">
              <b-dropdown-item @click="filters.teamFilter = team" v-for="team in teamOptions">{{ team.label }}</b-dropdown-item>
            </b-dropdown>
          </div>
          <div :class="(filters.quarterFilter) ? 'mb-md-0 mb-2 hasValue' : 'mb-md-0 mb-2'">
            <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :value="filters.quarterFilter" v-model="filters.quarterFilter"
              :options="quarterOptions" class="w-100"  placeholder="Quarter" />
          </div>
          <div :class="(filters.yearFilter) ? 'mb-md-0 mb-2 hasValue' : 'mb-md-0 mb-2'">
            <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :value="filters.yearFilter" v-model="filters.yearFilter"
              :options="yearOptions" class="w-100"  placeholder="Year" />
          </div>
          <div class="mb-md-0 mb-2">
            <b-button variant="light-secondary" class="clear-btn" @click="resetFilters">Clear All</b-button>
          </div>
        </div>
      </b-collapse>
      <b-table :items="items" :fields="fields" striped responsive :class="'mb-0 customize-okrtable main-table '+getClass">
        <template #cell(name)="row">
          <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
          <div class="d-flex align-items-center">
            <b-form-checkbox v-if="pageType != 'review-okrs'" v-model="row.detailsShowing" @change="row.toggleDetails" class="pl-0">
              <svg xmlns="http://www.w3.org/2000/svg" width="7" height="14" viewBox="0 0 7 14" fill="none">
                <path
                  d="M6.66489 7.85465L1.26596 13.7558C1.19149 13.8372 1.11082 13.8983 1.02394 13.939C0.937057 13.9797 0.843972 14 0.744681 14C0.546099 14 0.37234 13.9254 0.223404 13.7762C0.0744681 13.6269 -2.49053e-08 13.4302 -3.5579e-08 13.186L-5.7638e-07 0.813953C-5.87054e-07 0.569767 0.0744675 0.373062 0.223404 0.223837C0.37234 0.0746116 0.546099 -2.38708e-08 0.74468 -3.2551e-08C0.794326 -3.47211e-08 0.968085 0.0813951 1.26596 0.244185L6.66489 6.14535C6.78901 6.28101 6.87589 6.41667 6.92553 6.55232C6.97518 6.68798 7 6.83721 7 7C7 7.16279 6.97518 7.31202 6.92553 7.44767C6.87589 7.58333 6.78901 7.71899 6.66489 7.85465Z"
                  fill="#6E6B7B" />
              </svg>
            </b-form-checkbox>
            <span v-if="pageType != 'review-okrs'" class="user-name text-black cursor-pointer pl-75"
              @click="openDetails(row.item)"><u>{{row.item.name}}</u>
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="12" viewBox="0 0 11 12" fill="none"
                class="ml-50 cursor-pointer">
                <path
                  d="M5.66667 2.50098H2.16667C1.85725 2.50098 1.5605 2.62389 1.34171 2.84269C1.12292 3.06148 1 3.35822 1 3.66764V9.50098C1 9.8104 1.12292 10.1071 1.34171 10.3259C1.5605 10.5447 1.85725 10.6676 2.16667 10.6676H8C8.30942 10.6676 8.60616 10.5447 8.82496 10.3259C9.04375 10.1071 9.16667 9.8104 9.16667 9.50098V6.00098"
                  stroke="#C3C3C3" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M5.08594 6.58301L10.3359 1.33301" stroke="#C3C3C3" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M7.41797 1.33301H10.3346V4.24967" stroke="#C3C3C3" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg></span>
              <b-link class="review-link" v-else  :to="{
                name: 'review-okr',
                params: { id: row.item.user,year:row.item.year,quarter:row.item.quarter },
              }">

              <span class="user-name text-black cursor-pointer pl-75"><u>{{row.item.name}}</u>
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="12" viewBox="0 0 11 12" fill="none"
                class="ml-50 cursor-pointer">
                <path
                  d="M5.66667 2.50098H2.16667C1.85725 2.50098 1.5605 2.62389 1.34171 2.84269C1.12292 3.06148 1 3.35822 1 3.66764V9.50098C1 9.8104 1.12292 10.1071 1.34171 10.3259C1.5605 10.5447 1.85725 10.6676 2.16667 10.6676H8C8.30942 10.6676 8.60616 10.5447 8.82496 10.3259C9.04375 10.1071 9.16667 9.8104 9.16667 9.50098V6.00098"
                  stroke="#C3C3C3" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M5.08594 6.58301L10.3359 1.33301" stroke="#C3C3C3" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M7.41797 1.33301H10.3346V4.24967" stroke="#C3C3C3" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg></span>
            </b-link>
          </div>
        </template>
        <template #cell(title)="row">
          <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
          <div class="d-flex align-items-center">
            <b-form-checkbox v-model="row.detailsShowing" @change="row.toggleDetails" class="pl-0">
              <svg xmlns="http://www.w3.org/2000/svg" width="7" height="14" viewBox="0 0 7 14" fill="none">
                <path
                  d="M6.66489 7.85465L1.26596 13.7558C1.19149 13.8372 1.11082 13.8983 1.02394 13.939C0.937057 13.9797 0.843972 14 0.744681 14C0.546099 14 0.37234 13.9254 0.223404 13.7762C0.0744681 13.6269 -2.49053e-08 13.4302 -3.5579e-08 13.186L-5.7638e-07 0.813953C-5.87054e-07 0.569767 0.0744675 0.373062 0.223404 0.223837C0.37234 0.0746116 0.546099 -2.38708e-08 0.74468 -3.2551e-08C0.794326 -3.47211e-08 0.968085 0.0813951 1.26596 0.244185L6.66489 6.14535C6.78901 6.28101 6.87589 6.41667 6.92553 6.55232C6.97518 6.68798 7 6.83721 7 7C7 7.16279 6.97518 7.31202 6.92553 7.44767C6.87589 7.58333 6.78901 7.71899 6.66489 7.85465Z"
                  fill="#6E6B7B" />
              </svg>
            </b-form-checkbox>
            <span  @click="openDetails(row.item)" class="user-name text-black cursor-pointer pl-75"
              ><u>{{row.item.title}}</u>
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="12" viewBox="0 0 11 12" fill="none"
                class="ml-50 cursor-pointer">
                <path
                  d="M5.66667 2.50098H2.16667C1.85725 2.50098 1.5605 2.62389 1.34171 2.84269C1.12292 3.06148 1 3.35822 1 3.66764V9.50098C1 9.8104 1.12292 10.1071 1.34171 10.3259C1.5605 10.5447 1.85725 10.6676 2.16667 10.6676H8C8.30942 10.6676 8.60616 10.5447 8.82496 10.3259C9.04375 10.1071 9.16667 9.8104 9.16667 9.50098V6.00098"
                  stroke="#C3C3C3" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M5.08594 6.58301L10.3359 1.33301" stroke="#C3C3C3" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M7.41797 1.33301H10.3346V4.24967" stroke="#C3C3C3" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg></span>
          </div>
        </template>
        <template #cell(team)="row" v-if="filters.typeFilter == 'Team' && pageType == 'all-okrs' ">
          <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
          <div class="d-flex align-items-center">
            <b-form-checkbox v-model="row.detailsShowing" @change="row.toggleDetails" class="pl-0">
              <svg xmlns="http://www.w3.org/2000/svg" width="7" height="14" viewBox="0 0 7 14" fill="none">
                <path
                  d="M6.66489 7.85465L1.26596 13.7558C1.19149 13.8372 1.11082 13.8983 1.02394 13.939C0.937057 13.9797 0.843972 14 0.744681 14C0.546099 14 0.37234 13.9254 0.223404 13.7762C0.0744681 13.6269 -2.49053e-08 13.4302 -3.5579e-08 13.186L-5.7638e-07 0.813953C-5.87054e-07 0.569767 0.0744675 0.373062 0.223404 0.223837C0.37234 0.0746116 0.546099 -2.38708e-08 0.74468 -3.2551e-08C0.794326 -3.47211e-08 0.968085 0.0813951 1.26596 0.244185L6.66489 6.14535C6.78901 6.28101 6.87589 6.41667 6.92553 6.55232C6.97518 6.68798 7 6.83721 7 7C7 7.16279 6.97518 7.31202 6.92553 7.44767C6.87589 7.58333 6.78901 7.71899 6.66489 7.85465Z"
                  fill="#6E6B7B" />
              </svg>
            </b-form-checkbox>
            <span class="user-name text-black cursor-pointer pl-75"
            @click="openDetails(row.item)"><u>{{row.item.team}}</u>
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="12" viewBox="0 0 11 12" fill="none"
                class="ml-50 cursor-pointer">
                <path
                  d="M5.66667 2.50098H2.16667C1.85725 2.50098 1.5605 2.62389 1.34171 2.84269C1.12292 3.06148 1 3.35822 1 3.66764V9.50098C1 9.8104 1.12292 10.1071 1.34171 10.3259C1.5605 10.5447 1.85725 10.6676 2.16667 10.6676H8C8.30942 10.6676 8.60616 10.5447 8.82496 10.3259C9.04375 10.1071 9.16667 9.8104 9.16667 9.50098V6.00098"
                  stroke="#C3C3C3" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M5.08594 6.58301L10.3359 1.33301" stroke="#C3C3C3" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M7.41797 1.33301H10.3346V4.24967" stroke="#C3C3C3" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg></span>
          </div>
        </template>
        <template #cell(progress)="row">
          <div class="d-flex align-items-center">
            <b-progress :value="row.item.progress" max="100" class="flat-progress"></b-progress>
            <span class="ml-50">{{row.item.progress}}%</span>
          </div>
        </template>
        <!-- full detail on click -->
        <template #row-details="row">
          <ul class="m-0 p-0 tree h-100 nested-okr">
            <li class="list-unstyled nested-table">
              <ul class="m-0  sub-tree">
                <li class="list-unstyled mt-0">
                  <b-table :items="row.item.key_results" v-if="row.item.key_results" :fields="expand_fields" striped responsive class="mb-0 customize-okrtable"
                    thead-class="d-none">
                    <template #cell(result_description)="key_result">
                      <span class="user-name text-black">{{key_result.item.result_description}}</span>
                    </template>
                    <template #cell(progress_percentage)="key_result">
                      <div class="d-flex align-items-center sub-progress">
                        <b-progress :value="key_result.item.progress_percentage" max="100" class="flat-progress"></b-progress>
                        <span class="ml-50">{{key_result.item.progress_percentage}}%</span>
                      </div>
                    </template>
                    <template #cell(status)="key_result">
                      <b-badge :variant="getStatusVariant(key_result.item.status)">
                        <div class="dot"></div>{{ key_result.item.status }}
                      </b-badge>
                    </template>
                  </b-table>
                  <b-table :items="row.item.objectives" v-if="row.item.objectives" :fields="expand_fields" striped responsive class="mb-0 customize-okrtable"
                    thead-class="d-none">
                    <template #cell(title)="objective">
                      <span class="user-name text-black">{{objective.item.title}}</span>
                    </template>
                    <template #cell(progress)="objective">
                      <div class="d-flex align-items-center">
                        <b-progress :value="objective.item.overall_status.percentage" max="100" class="flat-progress"></b-progress>
                        <span class="ml-50">{{objective.item.overall_status.percentage}}%</span>
                      </div>
                    </template>
                    <template #cell(status)="objective">
                      <b-badge :variant="getStatusVariant(objective.item.status)">
                        <div class="dot"></div> {{ objective.item.status }}
                      </b-badge>
                    </template>
                    <template #cell()="key_result">
                      <b-badge></b-badge>
                    </template>
                  </b-table>
                </li>
              </ul>
            </li>
          </ul>
        </template>
        <template #cell(review_status)="row">
          <b-badge :variant="getStatusVariant(row.item.review_status)">
            <div class="dot"></div> {{ row.item.review_status }}
          </b-badge>
        </template>
        <template #cell(status)="row">
          <b-badge :variant="getStatusVariant(row.item.status)">
            <div class="dot"></div> {{ row.item.status }}
          </b-badge>
        </template>
      </b-table>
      <b-row class="justify-content-end mx-2">
        <b-pagination v-if="this.pageType != 'review-okrs'" v-model="filters.currentPage" :total-rows="total" :per-page="per_page" first-number last-number
          class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-row>
    </b-card>
    <!-- OKR Detailed View Popup-->
    <b-modal id="detailed-group-okr" hide-footer>
      <template #modal-title #modal-header="{ close }">
        <span v-if="filters.typeFilter == 'Individual'">{{selectedRow.name}}</span>
        <span v-if="filters.typeFilter == 'Team'">{{selectedRow.team}}</span>’s {{ selectedRow.quarter }}, {{selectedRow.year}} OKR
        <feather-icon class="cursor-pointer floating-close" icon="XIcon" size="20"
          @click="$bvModal.hide('detailed-group-okr')" />
      </template>
      <!-- Viewer Details Okr Content -->
      <ul class="m-0 p-1 tree" v-if="selectedRow">
        <li class="list-unstyled mb-75" v-if="userData.hashid != objective.user.hashid" v-for="objective in selectedRow.objectives">
          <!-- third view -->
          <div class="okr-detailview position-relatives">
            <div class="progress-state">
              <b-badge class="ml-auto" :variant="getStatusVariant(objective.status)"><div class="dot"></div> {{ objective.status }}</b-badge>
            </div>
            <b-row class="justify-content-between w-100 align-items-center m-0 after-expand" v-b-toggle="'KeyResultsView-' + objective.hashid">
              <b-col sm="10" md="10" class="pl-0 d-flex align-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 7 14" fill="none" class="click-icon">
                  <path d="M6.66489 7.85465L1.26596 13.7558C1.19149 13.8372 1.11082 13.8983 1.02394 13.939C0.937057 13.9797 0.843972 14 0.744681 14C0.546099 14 0.37234 13.9254 0.223404 13.7762C0.0744681 13.6269 -2.49053e-08 13.4302 -3.5579e-08 13.186L-5.7638e-07 0.813953C-5.87054e-07 0.569767 0.0744675 0.373062 0.223404 0.223837C0.37234 0.0746116 0.546099 -2.38708e-08 0.74468 -3.2551e-08C0.794326 -3.47211e-08 0.968085 0.0813951 1.26596 0.244185L6.66489 6.14535C6.78901 6.28101 6.87589 6.41667 6.92553 6.55232C6.97518 6.68798 7 6.83721 7 7C7 7.16279 6.97518 7.31202 6.92553 7.44767C6.87589 7.58333 6.78901 7.71899 6.66489 7.85465Z" fill="#6E6B7B" />
                </svg>
                <h5 class="main-header mb-0 w-100 pl-50">{{objective.title}}</h5>
              </b-col>
              <b-col sm="2" md="2" class="okr-status d-flex align-ites-center justify-content-md-end pr-0 py-25">
                <div class="circle_percent" :percent="Number(objective.overall_status.percentage)">
                  <div class="circle_inner">
                    <div class="round_per"></div>
                  </div>
                </div>
              </b-col>
            </b-row>
            <!-- <div class="expand-div" v-b-toggle="'KeyResultsView-' + objective.hashid">
              <div class="d-flex align-items-center justify-content-center expand-old">
                <feather-icon icon="PlusIcon" class="before-expand" />
                <feather-icon icon="MinusIcon" class="after-expand" />
              </div>
            </div> -->
            <b-collapse :id="'KeyResultsView-' + objective.hashid" class="w-100 border-top mt-50">
            <ul class="sub-tree pl-2 pt-50">
              <li class="list-unstyled border-0 w-100 mt-0 mb-25"  v-for="(key_result,k) in objective.key_results">
                <div class="bordered-liness">
                  <!-- <b-badge class="ml-auto" :variant="getStatusVariant(key_result.status)"><div class="dot"></div> {{ key_result.status }}</b-badge> -->
                      <b-row class="overall-progress main-header border-bottom mx-0 pt-50 d-flex align-items-center border-0 pb-75">
                        <b-col class="align-items-center pl-0" sm="8" md="8">
                        <label>{{key_result.result_description}}</label>
                        <!-- <div class="final-value">
                          <small class="mb-25">Progress</small>
                          <p class="mb-0" v-if="key_result.metric_type == 'Boolean'">{{ (key_result.state &&
                            key_result.state == 'Yes') ? 'Completed' : 'Incomplete' }}</p>
                          <p class="mb-0" v-if="key_result.metric_type == 'Number'">{{ key_result.progress + " / "+key_result.goal}}</p>
                          <p class="mb-0" v-if="key_result.metric_type == 'Percentage'">{{ key_result.progress +
                            " / " + key_result.goal + "%" }}</p>
                        </div> -->
                    </b-col>
                    <b-col sm="4" md="4" class="justify-content-md-end d-flex pr-0 text-md-right">
                      <div class="input-value">
                        <p class="mb-0" v-if="key_result.metric_type == 'Boolean'">{{ (key_result.state && key_result.state == 'Yes') ? 'Completed' : 'Incomplete' }}</p>
                        <p class="mb-0" v-if="key_result.metric_type == 'Number'">{{ key_result.progress + " / "+key_result.goal}}</p>
                        <p class="mb-0" v-if="key_result.metric_type == 'Percentage'">{{ key_result.progress +
                        " / " + key_result.goal + "%" }}</p>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </li>
            </ul>
          </b-collapse>
          </div>
        </li>
        <!-- Owner Details Okr Content -->
        <li class="list-unstyled mb-75" v-if="userData.hashid == objective.user.hashid" v-for="objective in selectedRow.objectives">
          <div v-if="!objective.isInReiewState">
            <div class="okr-detailview position-relative owner-detail" >
              <div class="progress-state">
                <b-badge class="ml-auto" :variant="getStatusVariant(objective.status)"><div class="dot"></div> {{ objective.status }}</b-badge>
              </div>
              <!-- <b-button variant="outline-secondary update-okr" @click="objective.isInReiewState = true"  pill>Update</b-button> -->
              <b-row class="align-items-center justify-content-between w-100 m-0 after-expand" v-b-toggle="'KeyResultsEdit-' + objective.hashid">
                        <b-col class="d-flex align-items-center pl-0" sm="10" md="10">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 7 14" fill="none" class="click-icon">
                            <path
                              d="M6.66489 7.85465L1.26596 13.7558C1.19149 13.8372 1.11082 13.8983 1.02394 13.939C0.937057 13.9797 0.843972 14 0.744681 14C0.546099 14 0.37234 13.9254 0.223404 13.7762C0.0744681 13.6269 -2.49053e-08 13.4302 -3.5579e-08 13.186L-5.7638e-07 0.813953C-5.87054e-07 0.569767 0.0744675 0.373062 0.223404 0.223837C0.37234 0.0746116 0.546099 -2.38708e-08 0.74468 -3.2551e-08C0.794326 -3.47211e-08 0.968085 0.0813951 1.26596 0.244185L6.66489 6.14535C6.78901 6.28101 6.87589 6.41667 6.92553 6.55232C6.97518 6.68798 7 6.83721 7 7C7 7.16279 6.97518 7.31202 6.92553 7.44767C6.87589 7.58333 6.78901 7.71899 6.66489 7.85465Z"
                              fill="#6E6B7B" />
                          </svg>
                          <h5 class="main-header my-1 w-100 pl-50">{{objective.title}}</h5>
                        </b-col>
                        <b-col class="okr-status d-flex align-ites-center justify-content-md-end pr-0 py-25" sm="2" md="2">
                          <!-- <div class="d-flex align-items-center sub-progress">
                            <b-progress :value="objective.overall_status.percentage" max="100" class="flat-progress"></b-progress>
                            <span class="ml-50">{{objective.overall_status.percentage}}%</span>
                          </div> -->
                          <div class="circle_percent" :percent="Number(objective.overall_status.percentage)">
                            <div class="circle_inner">
                              <div class="round_per"></div>
                            </div>
                          </div>
                      </b-col>
                    </b-row>
              <!-- <h5>{{objective.title}}</h5> -->
              <!-- <div>
                <h6 class="mb-0">Overall Progress </h6>
                <div class="d-flex align-items-center justify-content-center">
                  <b-progress :value="objective.overall_status.percentage" max="100" class="flat-progress"></b-progress>
                  <span class="ml-50">{{objective.overall_status.percentage}}%</span>
                </div>
              </div> -->
              <!-- <div class="expand-div" v-b-toggle="'KeyResultsEdit-'+objective.hashid">
                <div class="d-flex align-items-center justify-content-center expand-old">
                  <feather-icon icon="PlusIcon" class="before-expand" />
                  <feather-icon icon="MinusIcon" class="after-expand" />
                </div>
              </div> -->
              <b-collapse :id="'KeyResultsEdit-'+objective.hashid" class="w-100">
                <ul class="m-0 p-0 tree h-100 nested-okr overflow-visible">
                  <li class="list-unstyled nested-table">
                    <ul class="m-0 pl-0 sub-tree">
                      <li class="list-unstyled border-0" >
                              <div>
                                <b-row
                                  class="overall-progress main-header border-bottom mx-0 pt-50 d-flex align-items-center border-0 pb-75">
                                  <b-col sm="12" md="7" class="pl-0">
                                    <div class="title-sub main-sub">Overall Progress</div>
                                  </b-col>
                                  <b-col sm="12" md="5" class="pr-0 text-md-right status-drop">
                                    <!-- <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="objective.status"
                                      :options="statusOptions" class="w-100"
                                      placeholder="In Progress" /> -->
                                      <b-dropdown variant="outline-secondary" :text="objective.status" placeholder="Status" class="custom-multiselect">
                                        <b-dropdown-item @click="objective.status = status"
                                          v-for="status in statusOptions">{{ status }}</b-dropdown-item>
                                      </b-dropdown>
                                  </b-col>
                                </b-row>
                                <b-row v-for="(key_result,k) in objective.key_results" :key="k"
                                  class="overall-progress main-header border-bottom mx-0 py-1 d-flex align-items-center">
                                  <b-col sm="12" md="8" class="pl-0" >
                                    <div class="title-sub">{{key_result.result_description}}</div>
                                  </b-col>
                                  <b-col sm="12" md="4" class="pr-0 d-flex align-items-center justify-content-md-end">
                                    <div class="">
                                      <!-- <h6 v-if="key_result.metric_type != 'Boolean'">Progress</h6>
                                      <h6 v-else>Is Completed</h6> -->
                                      <div class="d-flex align-items-center report-value yesno-switch" v-if="key_result.metric_type != 'Boolean'">
                                        <input type="number" v-model="key_result.progress" class="enabled" >
                                        <span class="mx-50">/</span>
                                        <input type="number" v-model="key_result.goal" class="disabled border-0">
                                      </div>
                                      <div class="d-flex align-items-center report-value justify-content-md-end yesno-switch" v-else >
                                        <b-button :class="(key_result.state && key_result.state == 'Yes')?'yes-btn selected':'yes-btn'"  @click="key_result.state='Yes'" variant="outline-secondary">Yes</b-button>
                                        <b-button :class="(!key_result.state || key_result.state == 'No')?'no-btn selected':'no-btn'"   @click="key_result.state='No'" variant="outline-secondary">No</b-button>
                                      </div>
                                    </div>
                                  </b-col>
                                </b-row>
                              </div>
                            </li>
                      </ul>
                    </li>
                </ul>
                <div class="d-flex align-items-end justify-content-end py-1 shadow-none update-btn rounded-0 border-0 m-0">
                  <b-button variant="outline-secondary" class="w-auto" @click="refreshPopup()" pill>Cancel</b-button>
                  <b-button variant="primary" class="w-auto ml-1"  @click="updateObjectiveProgress(objective)" pill>Update </b-button>
                </div>
            </b-collapse>
            </div>
          </div>
        </li>
        <!-- Owner Content -->
        <!-- End Update-->
      </ul>
      <!-- End Viewer Content -->
      <!-- Fixed Footer Related Okr Content -->
      <!-- <div class="fixed-okr w-100">
        <h4 class="middle-header">Related OKRs</h4>
        <ul class="m-0 p-0 px-1">
          <li class="list-unstyled my-75">
            <div class="d-flex align-items-center justify-content-between bordered-line">
              <h5 class="mb-0 pb-0 w-md-50">Brighty’s Q3 OKR</h5>
              <div class="related-okrstatus d-flex align-items-center w-50 justify-content-between">
                <div class="d-flex align-items-center justify-content-center">
                  <b-progress :value="100" max="100" class="flat-progress"></b-progress>
                  <span class="ml-50">100%</span>
                </div>
                <b-badge variant="light-primary">Completed</b-badge>
              </div>
            </div>
          </li>
          <li class="list-unstyled my-75">
            <div class="d-flex align-items-center justify-content-between bordered-line">
              <h5 class="mb-0 pb-0 w-md-50">Brighty’s Q3 OKR</h5>
              <div class="related-okrstatus d-flex align-items-center w-50 justify-content-between">
                <div class="d-flex align-items-center justify-content-center">
                  <b-progress :value="100" max="100" class="flat-progress"></b-progress>
                  <span class="ml-50">100%</span>
                </div>
                <b-badge variant="light-primary">Completed</b-badge>
              </div>
            </div>
          </li>
          <li class="list-unstyled my-75">
            <div class="d-flex align-items-center justify-content-between bordered-line">
              <h5 class="mb-0 pb-0 w-md-50">Brighty’s Q3 OKR</h5>
              <div class="related-okrstatus d-flex align-items-center w-50 justify-content-between">
                <div class="d-flex align-items-center justify-content-center">
                  <b-progress :value="100" max="100" class="flat-progress"></b-progress>
                  <span class="ml-50">100%</span>
                </div>
                <b-badge variant="light-primary">Completed</b-badge>
              </div>
            </div>
          </li>
        </ul>
      </div> -->
    </b-modal>
    <b-modal id="single-objective" hide-footer>
      <template #modal-title #modal-header="{ close }">
        <span v-if="selectedObjective.type == 'Individual'">{{selectedObjective.user.name}}</span><span v-if="selectedObjective.type == 'Team'">{{selectedObjective.team_name}}</span><span v-if="selectedObjective.type == 'Organisation'">Organisation</span>’s {{ selectedObjective.quarter }}, {{selectedObjective.year}} OKR
        <feather-icon class="cursor-pointer floating-close" icon="XIcon" size="20"
          @click="$bvModal.hide('single-objective')" />
      </template>
      <!-- Viewer Details Okr Content -->
      <ul class="m-0 p-1 tree" v-if="selectedObjective">
        <li class="list-unstyled mb-75" v-if="userData.hashid != selectedObjective.user.hashid" v-for="objective in [selectedObjective]">
          <div class="okr-detailview position-relative">
            <div class="progress-state">
              <b-badge class="ml-auto" :variant="getStatusVariant(objective.status)"> {{ objective.status }}</b-badge>
            </div>
            <b-row class="d-flex align-items-center justify-content-between w-100 m-0 after-expand" v-b-toggle="'KeyResultsView-' + objective.hashid">
              <b-col sm="10" md="10" class="pl-0 d-flex align-items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 7 14" fill="none" class="click-icon">
                  <path d="M6.66489 7.85465L1.26596 13.7558C1.19149 13.8372 1.11082 13.8983 1.02394 13.939C0.937057 13.9797 0.843972 14 0.744681 14C0.546099 14 0.37234 13.9254 0.223404 13.7762C0.0744681 13.6269 -2.49053e-08 13.4302 -3.5579e-08 13.186L-5.7638e-07 0.813953C-5.87054e-07 0.569767 0.0744675 0.373062 0.223404 0.223837C0.37234 0.0746116 0.546099 -2.38708e-08 0.74468 -3.2551e-08C0.794326 -3.47211e-08 0.968085 0.0813951 1.26596 0.244185L6.66489 6.14535C6.78901 6.28101 6.87589 6.41667 6.92553 6.55232C6.97518 6.68798 7 6.83721 7 7C7 7.16279 6.97518 7.31202 6.92553 7.44767C6.87589 7.58333 6.78901 7.71899 6.66489 7.85465Z" fill="#6E6B7B" />
                </svg>
                <h5 class="main-header mb-0 w-100 pl-50">{{objective.title}}</h5>
              </b-col>
              <b-col sm="2" md="2" class="okr-status d-flex align-ites-center justify-content-md-end pr-0 py-25">
                <div class="circle_percent" :percent="Number(objective.overall_status.percentage)">
                  <div class="circle_inner">
                    <div class="round_per"></div>
                  </div>
                </div>
              </b-col>
            </b-row>
            <!-- <div class="expand-div" v-b-toggle="'KeyResultsView-' + objective.hashid">
              <div class="d-flex align-items-center justify-content-center expand-old">
                <feather-icon icon="PlusIcon" class="before-expand" />
                <feather-icon icon="MinusIcon" class="after-expand" />
              </div>
            </div> -->
            <b-collapse :id="'KeyResultsView-' + objective.hashid" class="w-100 border-top mt-50">
                <ul class="sub-tree pl-2 pt-50">
                  <li class="list-unstyled border-0 w-100"  v-for="(key_result,k) in objective.key_results">
                    <div class="bordered-liness ">
                      <!-- <div class="progress-state">
                        <b-badge class="ml-auto" :variant="getStatusVariant(key_result.status)"> {{ key_result.status }}</b-badge>
                      </div> -->
                      <b-row class="border-bottom mx-0 pt-50 d-flex align-items-center border-0 pb-75">
                          <b-col class="align-items-center pl-0" sm="8" md="8">
                              <h5>{{key_result.result_description}}</h5>
                            <!-- <div class="final-value">
                              <small class="mb-25">Progress</small>
                              <p class="mb-0" v-if="key_result.metric_type == 'Boolean'">{{ (key_result.state &&
                                key_result.state == 'Yes') ? 'Completed' : 'Incomplete' }}</p>
                              <p class="mb-0" v-if="key_result.metric_type == 'Number'">{{ key_result.progress + " / "+key_result.goal}}</p>
                              <p class="mb-0" v-if="key_result.metric_type == 'Percentage'">{{ key_result.progress +
                                " / " + key_result.goal + "%" }}</p>
                            </div> -->
                        </b-col>
                        <b-col sm="4" md="4" class="justify-content-md-end d-flex pr-0 text-md-right">
                          <div class="input-value">
                            <p class="mb-0" v-if="key_result.metric_type == 'Boolean'">{{ (key_result.state && key_result.state == 'Yes') ? 'Completed' : 'Incomplete' }}</p>
                            <p class="mb-0" v-if="key_result.metric_type == 'Number'">{{ key_result.progress + " / "+key_result.goal}}</p>
                            <p class="mb-0" v-if="key_result.metric_type == 'Percentage'">{{ key_result.progress + " / " + key_result.goal + "%" }}</p>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </li>
                </ul>
              </b-collapse>
          </div>
        </li>
        <!-- Owner Details Okr Content -->
        <li class="list-unstyled mb-75" v-if="userData.hashid == objective.user.hashid" v-for="objective in [selectedObjective]">
          <div v-if="!objective.isInReiewState">
            <div class="okr-detailview position-relative updateokr-detail">
              <div class="progress-state">
                <b-badge class="ml-auto" :variant="getStatusVariant(objective.status)"> {{ objective.status }}</b-badge>
              </div>
              <!-- <b-button variant="outline-primary update-okr" @click="updateObjectiveProgress(objective)"  pill>Update</b-button> -->
              <b-row class="align-items-center justify-content-between w-100 m-0 after-expand" v-b-toggle="'KeyResultsEdit-' + objective.hashid">
                        <b-col class="pl-0 d-flex align-items-center" sm="10" md="10">
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 7 14" fill="none" class="click-icon">
                            <path
                              d="M6.66489 7.85465L1.26596 13.7558C1.19149 13.8372 1.11082 13.8983 1.02394 13.939C0.937057 13.9797 0.843972 14 0.744681 14C0.546099 14 0.37234 13.9254 0.223404 13.7762C0.0744681 13.6269 -2.49053e-08 13.4302 -3.5579e-08 13.186L-5.7638e-07 0.813953C-5.87054e-07 0.569767 0.0744675 0.373062 0.223404 0.223837C0.37234 0.0746116 0.546099 -2.38708e-08 0.74468 -3.2551e-08C0.794326 -3.47211e-08 0.968085 0.0813951 1.26596 0.244185L6.66489 6.14535C6.78901 6.28101 6.87589 6.41667 6.92553 6.55232C6.97518 6.68798 7 6.83721 7 7C7 7.16279 6.97518 7.31202 6.92553 7.44767C6.87589 7.58333 6.78901 7.71899 6.66489 7.85465Z"
                              fill="#6E6B7B" />
                          </svg>
                          <h4 class="main-header my-1 w-100 pl-50">{{ objective.title }}</h4>
                        </b-col>
                        <b-col class="okr-status d-flex align-ites-center justify-content-md-end pr-0 py-25" sm="2" md="2">
                          <div class="circle_percent" :percent="Number(objective.overall_status.percentage)">
                            <div class="circle_inner">
                              <div class="round_per"></div>
                            </div>
                          </div>
                      </b-col>
                    </b-row>
              <!-- <h5>{{objective.title}}</h5>
              <div>
                <h6 class="mb-0">Overall Progress </h6>
                <div class="d-flex align-items-center justify-content-center">
                  <b-progress :value="objective.overall_status.percentage" max="100" class="flat-progress"></b-progress>
                  <span class="ml-50">{{objective.overall_status.percentage}}%</span>
                </div>
              </div> -->
              <!-- <div class="expand-div" v-b-toggle="'KeyResultsEdit-'+objective.hashid">
                <div class="d-flex align-items-center justify-content-center expand-old">
                  <feather-icon icon="PlusIcon" class="before-expand" />
                  <feather-icon icon="MinusIcon" class="after-expand" />
                </div>
              </div> -->
                <b-collapse :id="'KeyResultsEdit-'+objective.hashid" class="w-100">
                  <ul class="m-0 p-0 tree h-100 nested-okr overflow-visible">
                    <li class="list-unstyled nested-table">
                      <ul class="m-0 pl-0 sub-tree">
                        <li class="list-unstyled border-0" >
                                <b-card class="shadow-none mb-0 rounded-top border-0">
                                  <b-row
                                    class="overall-progress main-header border-bottom mx-0 pt-50 d-flex align-items-center border-0 pb-75">
                                    <b-col sm="12" md="7" class="pl-0">
                                      <div class="title-sub main-sub">Overall Progress</div>
                                    </b-col>
                                    <b-col sm="12" md="5" class="pr-0 text-md-right status-drop">
                                      <!-- <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="objective.status"
                                        :options="statusOptions" class="w-100"
                                        placeholder="In Progress" /> -->
                                        <b-dropdown variant="outline-secondary" :text="objective.status" placeholder="Status" class="custom-multiselect">
                                          <b-dropdown-item @click="objective.status = status"
                                            v-for="status in statusOptions">{{ status }}</b-dropdown-item>
                                        </b-dropdown>
                                    </b-col>
                                  </b-row>
                                  <b-row v-for="(key_result,k) in objective.key_results"
                                    class="overall-progress main-header border-bottom mx-0 pt-50 d-flex align-items-center border-0 pb-75">
                                    <b-col sm="12" md="8" class="pl-0" >
                                      <div class="title-sub">{{key_result.result_description}}</div>
                                    </b-col>
                                    <b-col sm="12" md="4" class="pr-0 d-flex align-items-center justify-content-md-end">
                                      <div class="">
                                        <!-- <h6 v-if="key_result.metric_type != 'Boolean'">Progress</h6>
                                        <h6 v-else>Is Completed</h6> -->
                                        <div class="d-flex align-items-center report-value" v-if="key_result.metric_type != 'Boolean'">
                                          <input type="number" v-model="key_result.progress" class="enabled" >
                                          <span>/</span>
                                          <input type="number" v-model="key_result.goal" class="disabled border-0">
                                        </div>
                                        <div class="d-flex align-items-center report-value justify-content-md-end yesno-switch" v-else >
                                          <b-button :class="(key_result.state && key_result.state == 'Yes')?'yes-btn selected':'yes-btn'"  @click="key_result.state='Yes'" variant="outline-secondary">Yes</b-button>
                                          <b-button :class="(!key_result.state || key_result.state == 'No')?'no-btn selected':'no-btn'"   @click="key_result.state='No'" variant="outline-secondary">No</b-button>
                                        </div>
                                      </div>
                                    </b-col>
                                  </b-row>
                                </b-card>
                              </li>
                        </ul>
                      </li>
                  </ul>
                  <div class="d-flex align-items-end justify-content-end py-1 shadow-none update-btn rounded-0 border-0 m-0">
                    <b-button variant="outline-secondary" class="w-auto" @click="selectedObjective = temRow" pill>Cancel</b-button>
                    <b-button variant="primary" class="w-auto ml-1"  @click="updateObjectiveProgress(objective)" pill>Update </b-button>
                  </div>
              </b-collapse>
            </div>
           
          </div>
        </li>
        <!-- Owner Content -->
        <!-- End Update-->
      </ul>
      <!-- End Viewer Content -->
      <!-- Fixed Footer Related Okr Content -->
      <!-- <div class="fixed-okr w-100">
        <h4 class="middle-header">Related OKRs</h4>
        <ul class="m-0 p-0 px-1">
          <li class="list-unstyled my-75">
            <div class="d-flex align-items-center justify-content-between bordered-line">
              <h5 class="mb-0 pb-0 w-md-50">Brighty’s Q3 OKR</h5>
              <div class="related-okrstatus d-flex align-items-center w-50 justify-content-between">
                <div class="d-flex align-items-center justify-content-center">
                  <b-progress :value="70" max="100" class="flat-progress"></b-progress>
                  <span class="ml-50">70%</span>
                </div>
                <b-badge variant="light-secondary">In Progress</b-badge>
              </div>
            </div>
          </li>
          <li class="list-unstyled my-75">
            <div class="d-flex align-items-center justify-content-between bordered-line">
              <h5 class="mb-0 pb-0 w-md-50">Brighty’s Q3 OKR</h5>
              <div class="related-okrstatus d-flex align-items-center w-50 justify-content-between">
                <div class="d-flex align-items-center justify-content-center">
                  <b-progress :value="100" max="100" class="flat-progress"></b-progress>
                  <span class="ml-50">100%</span>
                </div>
                <b-badge variant="light-primary">Completed</b-badge>
              </div>
            </div>
          </li>
          <li class="list-unstyled my-75">
            <div class="d-flex align-items-center justify-content-between bordered-line">
              <h5 class="mb-0 pb-0 w-md-50">Brighty’s Q3 OKR</h5>
              <div class="related-okrstatus d-flex align-items-center w-50 justify-content-between">
                <div class="d-flex align-items-center justify-content-center">
                  <b-progress :value="10" max="100" class="flat-progress"></b-progress>
                  <span class="ml-50">10%</span>
                </div>
                <b-badge variant="light-secondary">In Progress</b-badge>
              </div>
            </div>
          </li>
        </ul>
      </div> -->
    </b-modal>
  </div>
</template>
<script>
import $ from "jquery";
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormRadio,
  BForm,
  BInputGroup,
  BInputGroupPrepend,
  BTabs,
  BTab,
  BListGroup,
  BListGroupItem,
  VBTooltip,
} from "bootstrap-vue";
// import { treeTable } from "./tree-table";
import { BAvatar, BModal, BImg, BButton } from 'bootstrap-vue';
import Vue from 'vue';
import { codeRowDetailsSupport } from './code'
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  name: 'example',
  components: {
  },
  created() {
    this.pageType = this.getPageType();
    this.getEmployeeOptions();
    this.getManagerOptions();
    this.getTeamOptions();
    const urlParams = new URLSearchParams(window.location.search);
    const userId = urlParams.get('employee_name');
    this.filters.searchQuery = userId ? userId : ""
  },
  watch: {
    pageType:function (val) {
      this.getItems();
    },
    '$route.fullPath'(newPath, oldPath) {
      this.pageType = this.getPageType();
      this.resetPagination();
      // Call your function here or perform actions based on the URL change
    },
    'filters.typeFilter':function (val) {
      this.resetPagination();
    },
    filters: {
      handler(newFilters, oldFilters) {
        // Call your function or perform actions here when filters change
        this.getItems();
      },
      deep: true, // Watch all properties of the filters object
    },
    
  '$route.query': function(val) {

    const filter = val.q; // Access the 'q' parameter directly from val
    console.log(filter)
    if (filter) {
      this.filters.searchQuery = filter; // Update the searchQuery data property
    }
  
},
  },
  data() {
    return {
      pageType:null,
      props: {
        stripe: false,
        border: false,
        showHeader: true,
        showSummary: false,
        showRowHover: true,
        showIndex: false,
        treeType: true,
        isFold: true,
        expandType: false,
        selectionType: false,
      },
      //filters

      typeOptions: ['Individual', 'Team', 'Organisation'],
      filters:{
        typeFilter: 'Individual',
        searchQuery: new URLSearchParams(window.location.search).get('q') ? new URLSearchParams(window.location.search).get('q') : "",
        employeeFilter: [],
        managerFilter: [],
        quarterFilter: '',
        yearFilter: '',
        statusFilter: '',
        reviewStatusFilter: '',
        currentPage: 1,
        teamFilter: '',
      },
      selectedEmployees:[],
      selectedManagers:[],
      searchEmployee:'',
      searchManager:'',
      selectedRow:null,
      selectedObjective:null,
      temRow:null,
      employeeOptions: [],
      managerOptions: [],
      quarterFilter: '',
      quarterOptions: ['Q1', 'Q2','Q3','Q4'],
      yearOptions: ['2024','2023'],
      statusOptions: ['Upcoming', 'Not Started Yet', 'In Progress', 'Completed', 'Cancelled', 'On Hold', 'Deferred'],
      reviewStatusOptions:['Approved','Approval Pending'],
      total: null,
      per_page: 10,
      teamOptions: [],

      userData: this.$cookies.get("userData"),

      myOKR_fields: [{ key: 'title', tdClass: 'name-table' }, 'progress', { key: 'status', label: 'Status' }, 'period'],
      objective_fields: [{ key: 'title', tdClass: 'name-table' }, 'progress', { key: 'status', label: 'Status' }, 'period'],
      key_result_fields : [{ key: 'result_description', tdClass: 'name-table' } ,'progress_percentage','status','test'],
      AllOKRs_fields: {
        individual_feilds: [{ key: 'name', tdClass: 'name-table' }, 'team', 'no_of_objectives', 'progress', { key: 'status', label: 'Status' }, 'period'],
        team_feilds: [{ key: 'team', tdClass: 'name-table' }, 'no_of_objectives', 'progress', { key: 'status', label: 'Status' }, 'period'],
        organisation_feilds: [{ key: 'title', tdClass: 'name-table' }, 'progress', { key: 'status', label: 'Status' }, 'period'],
      },
      review_feilds:[{ key: 'name', tdClass: 'name-table' }, 'period', 'no_of_objectives','review_status', { key: 'created_on', label: 'Created On' },{ key: 'edited_on', label: 'Edited On' }],
      
      fields: [{ key: 'name', tdClass: 'name-table' }, 'team', 'no_of_objectives', 'progress', { key: 'status', label: 'Status' }, 'period'
      ],
      items: [],      
      codeRowDetailsSupport,
    };
  },
  methods: {
    refreshPopup(){
      this.selectedRow = JSON.parse(JSON.stringify(this.temRow));
    },
    openDetails(row){
      if(this.pageType == 'all-okrs' && this.filters.typeFilter == 'Individual'){
        this.selectedRow = JSON.parse(JSON.stringify(row));
        this.temRow = JSON.parse(JSON.stringify(row));
        this.$bvModal.show('detailed-group-okr')
      }
      else if(this.pageType == 'all-okrs' && this.filters.typeFilter == 'Team'){
        this.selectedRow = JSON.parse(JSON.stringify(row));
        this.temRow = JSON.parse(JSON.stringify(row));
        this.$bvModal.show('detailed-group-okr')
      }
      else{
        this.temRow = {...row};
        this.selectedObjective = {...this.temRow};
        this.$bvModal.show('single-objective');
      }
      this.$nextTick(() => {
        this.updateProgress();
      });
    },
    updateProgress(){
      // Circle Progress bar Design
      $(".circle_percent").each(function() {
        var $this = $(this),
        $dataV = $this.attr('percent'),
        $dataDeg = $dataV * 3.6,
        $round = $this.find(".round_per");
        $round.css("transform", "rotate(" + parseInt($dataDeg + 180) + "deg)");	
        $this.append('<div class="circle_inbox"><span class="percent_text"></span></div>');
        $this.prop('Counter', 0).animate({Counter: $dataV},
        {
          duration: 2000, 
          easing: 'swing', 
          step: function (now) {
                  $this.find(".percent_text").text(Math.ceil(now)+"%");
              }
          });
        if($dataV >= 51){
          $round.css("transform", "rotate(" + 360 + "deg)");
          setTimeout(function(){
            $this.addClass("percent_more");
          },1000);
          setTimeout(function(){
            $round.css("transform", "rotate(" + parseInt($dataDeg + 180) + "deg)");
          },1000);
        } 
      });
    },
    getEmployeeOptions(){
      axios
      .get("/list/extended-employees")
      .then(response => {
        console.log(response);
          this.employeeOptions = response.data.data;
      })
      .catch((error) => {
          console.log(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Unable to fetch Employees",
              text: error.message,
              icon: "BellIcon",
              variant: "danger",
            },
          });
        });
    },
    getTeamOptions() {
      this.isloading = true;
      axios.get("/list/divisions")
        .then((response) => {
          this.teamOptions = response.data.data;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Unable to fetch Team Names",
              icon: "BellIcon",
              variant: "danger",
              text: error,
            },
          });
        });
    },
    getManagerOptions(){
      axios
      .get("/list/extended-managers")
      .then(response => {
          this.managerOptions = response.data.data;
      })
      .catch((error) => {
          console.log(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Unable to fetch Employees",
              text: error.message,
              icon: "BellIcon",
              variant: "danger",
            },
          });
        });
    },
    resetFilters(){
      this.filters = {
        typeFilter: this.filters.typeFilter,
        searchQuery: '',
        employeeFilter: [],
        managerFilter: [],
        quarterFilter: '',
        yearFilter: '',
        statusFilter: '',
        currentPage: 1,
        teamFilter: '',
        reviewStatusFilter:'',
      };

      this.selectedEmployees = [];
      this.selectedManagers = [];
      this.searchEmployee = '';
      this.searchManager ='';
      this.resetPagination();
    },
    getItems(){
      if(this.pageType == 'my-okrs'){
        this.fields=this.myOKR_fields;
        this.expand_fields=this.key_result_fields;
        this.getAllMyOKRs();
      }
      if(this.pageType == 'review-okrs'){
        this.fields=this.review_feilds;
        this.getMySubordinatesOKRs();
      }
      if(this.pageType == 'all-okrs'){
        if(this.filters.typeFilter == 'Organisation'){
            this.fields=this.AllOKRs_fields.organisation_feilds;
            this.expand_fields=this.key_result_fields;
            this.getOrganisationOKR();
        }
        if(this.filters.typeFilter == 'Team'){
            this.fields=this.AllOKRs_fields.team_feilds;
            this.getTeamOKR();
        }
        if(this.filters.typeFilter == 'Individual'){
            this.fields=this.AllOKRs_fields.individual_feilds;
            this.expand_fields = this.objective_fields;
            this.getIndivudualOKR();
        }
      }
    },
    getPageType(){
      const currentPath = window.location.pathname;
      const segments = currentPath.split('/');
      // Filter out any empty segments (e.g., due to leading or trailing slashes)
      const filteredSegments = segments.filter(segment => segment.trim() !== '');
      return filteredSegments[filteredSegments.length - 1];
    },
    confirmCloseButton() {
      this.$swal({
        title: "Are you sure?",
        text: "You are about to exit this form , if you proceed the form data will be lost.",
        showCancelButton: true,
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: "btn yes-btn",
          cancelButton: "btn btn-outline-secondary ml-1 cancel-btn",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$bvModal.hide("create-custompop");
          this.post_form_data = {
            title: '',
            channel_type: "community",
            post_anonymously: false,
            feedback_type: "",
            body: "",
            scheduled_time: moment().add(1, "days").format("YYYY-MM-DD ") + "09:30",
            scheduled_timezone: this.getUserTimezone(),
            is_scheduled: false,
            other_details: {
              post_type: 'text-post',
              author: ''
            }
          };
          this.myFiles = [];
          this.page2 = false;
        }
      });
    },
    switchTo(type){
      this.filters.typeFilter = type;
    },
    resetPagination(){
      this.filters.currentPage = 1;
      this.per_page = 10;
    },
    getStatusVariant(status){
      if(status == 'Upcoming'){
        return 'light-info';
      }else if(status == 'Not Started Yet'){
        return 'light-notyet';
      }else if(status == 'In Progress'){
        return 'light-warning';
      }else if(status == 'Completed'){
        return 'light-primary';
      }else if(status == 'Cancelled'){
        return 'light-danger';
      }else if(status == 'On Hold'){
        return 'light-dark';
      }else if(status == 'Deferred'){
        return 'light-dark';
      }else if(status == 'Submitted'){
        return 'light-dark';
      }else if(status == 'Approved'){
        return 'light-primary';
      }
      return 'light-warning';
    },
    getAllMyOKRs(){
      var params = {
        typeFilter : this.filters.typeFilter,
        searchQuery : this.filters.searchQuery,
        quarterFilter : this.filters.quarterFilter,
        statusFilter : this.filters.statusFilter,
        yearFilter : this.filters.yearFilter,
        currentPage : this.filters.currentPage,
        per_page : this.per_page,
        teamFilter : this.filters.teamFilter.label
      }
      axios
      .post("objectives/my-okrs", { params: params })
      .then(response => {
          this.items = response.data.data;
          this.total = response.data.meta.total;
          this.per_page = response.data.meta.per_page;
      })
      .catch((error) => {
          console.log(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Unable to fetch OKRs",
              text: error.message,
              icon: "BellIcon",
              variant: "danger",
            },
          });
        });
    },
    getOrganisationOKR(){
      var params = {
        typeFilter : this.filters.typeFilter,
        searchQuery : this.filters.searchQuery,
        quarterFilter : this.filters.quarterFilter,
        statusFilter : this.filters.statusFilter,
        yearFilter : this.filters.yearFilter,
        currentPage : this.filters.currentPage,
        per_page : this.per_page,
        teamFilter : this.filters.teamFilter.label,
      }
      axios
      .post("objectives/all-organisation-okrs", { params: params })
      .then(response => {
          this.items = response.data.data;
          this.total = response.data.meta.total;
          this.per_page = response.data.meta.per_page;
      })
      .catch((error) => {
          console.log(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Unable to fetch Orgnisation OKRs",
              text: error.message,
              icon: "BellIcon",
              variant: "danger",
            },
          });
        });
    },
    getIndivudualOKR(){
      var params = {
        typeFilter : this.filters.typeFilter,
        searchQuery : this.filters.searchQuery,
        quarterFilter : this.filters.quarterFilter,
        statusFilter : this.filters.statusFilter,
        yearFilter : this.filters.yearFilter,
        currentPage : this.filters.currentPage,
        per_page : this.per_page,
        teamFilter : this.filters.teamFilter.label,
        employeeFilter: this.filters.employeeFilter,
        managerFilter: this.filters.managerFilter,
      }
      axios
      .post("objectives/all-individual-okrs", { params: params })
      .then(response => {
          this.items = response.data.data;
          this.total = response.data.pagination.total;
          this.per_page = response.data.pagination.per_page;
      })
      .catch((error) => {
          console.log(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Unable to fetch Individual OKRs",
              text: error.message,
              icon: "BellIcon",
              variant: "danger",
            },
          });
        });
    },
    getTeamOKR(){
      var params = {
        typeFilter : this.filters.typeFilter,
        searchQuery : this.filters.searchQuery,
        quarterFilter : this.filters.quarterFilter,
        statusFilter : this.filters.statusFilter,
        yearFilter : this.filters.yearFilter,
        currentPage : this.filters.currentPage,
        per_page : this.per_page,
        teamFilter : this.filters.teamFilter.label,
        employeeFilter: this.filters.employeeFilter,
        managerFilter: this.filters.managerFilter,
      }
      axios
      .post("objectives/all-team-okrs", { params: params })
      .then(response => {
          this.items = response.data.data;
          this.total = response.data.pagination.total;
          this.per_page = response.data.pagination.per_page;
      })
      .catch((error) => {
          console.log(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Unable to fetch Team OKRs",
              text: error.message,
              icon: "BellIcon",
              variant: "danger",
            },
          });
        });
    },
    checkData(objectiveData) {
      var messages = [];
      if (objectiveData.title == ''){
        messages.push({'title':'Objective title is required','text':'Please enter objective title.'});       
      }
      // if (objectiveData.type != 'individual'){
      //   messages.push({'title':'Objective title is required','text':'Please enter objective title.'});       
      // }
      objectiveData.key_results.forEach(function(key_result,key) { 
        if(key_result.result_description == '' || key_result.result_description == null ){
          messages.push({'title':"Key Result is required #"+(key+1),'text':"Please Enter Key Result #"+(key+1)+" for Objective "});
        }
        if(key_result.metric_type == 'Number' || key_result.metric_type == 'Percentage' ){
          if(key_result.goal == '' || key_result.goal == null || key_result.goal == 0 || !(!isNaN(key_result.goal) && !isNaN(parseInt(key_result.goal)))){
            messages.push({'title':"Goal "+key_result.metric_type+" is required #"+(key+1),'text':"Please Valid Enter Goal "+key_result.metric_type+" for Key Result #"+(key+1)});
          }
          if((key_result.start < 0 || key_result.start == '' || key_result.start == null  || !(!isNaN(key_result.start) && !isNaN(parseInt(key_result.start))))){
            if(key_result.start != 0){
              if(key_result.start < 0){
                messages.push({'title':"Start "+key_result.metric_type+" is invalid #"+(key+1),'text':"Start "+key_result.metric_type+ "must be zero or positive for Key Result #"+(key+1)});
              }else{
                messages.push({'title':"Start "+key_result.metric_type+" is required #"+(key+1),'text':"Please Enter Valid Start "+key_result.metric_type+" for Key Result #"+(key+1)});
              }
            }
          }

          if(objectiveData.hashid){
            if(( key_result.progress == '' || key_result.progress == null  || !(!isNaN(key_result.progress) && !isNaN(parseInt(key_result.progress))))){
              messages.push({'title':"Progress "+key_result.metric_type+" is required #"+(key+1),'text':"Please Enter Valid Progress "+key_result.metric_type+" for Key Result #"+(key+1)});
            }
          }
          if(parseInt(key_result.start) >= parseInt(key_result.goal)){
            messages.push({'title':"Start "+key_result.metric_type+" is required #"+(key+1),'text':"Start must be less than Target for Key Result #"+(key+1)});
          }
          if(key_result.progress && parseInt(key_result.progress) < parseInt(key_result.start)){
            messages.push({'title':"Progress "+key_result.metric_type+" is not valid for #"+(key+1),'text':"Progress must be grater than Start("+key_result.start+") for Key Result #"+(key+1)});
          }
          
        }
      });

      var response = {"status":false,'messages':messages};
      if(messages.length == 0){
        var response = {"status":true,'messages':messages};
      }
      return response;      
    },
    updateObjectiveProgress(objective){
      var validData = this.checkData(objective);
      if(validData.status){
        axios.patch('objectives/'+objective.hashid, objective)
        .then((res) => {
            this.selectedObjective = res.data.data;
            if(this.selectedRow){
              for (let index = 0; index < this.selectedRow.objectives.length; index++) {
                const element = this.selectedRow.objectives[index];
                if(element.hashid == objective.hashid){
                  this.selectedRow.objectives[index] = res.data.data;
                  break;
                }            
              }
          }
          this.getItems();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "OKR Updated Successfully",
              icon: "BellIcon",
              variant: "success",
              text: res.data.message,
            },
          });
        })
        .catch((error) => {
          console.log(error)
          this.getMyOkRs();
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Oops! OKR Adding Failed",
                icon: "BellIcon",
                variant: "danger",
                text: error,
              },
            });
        });
      }
      else{
        for(var key in validData.messages){
         this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: validData.messages[key].title,
                text:validData.messages[key].text,
                icon: "BellIcon",
                variant: "danger",
              },
            }); 
        }
      }
    },

    getMySubordinatesOKRs(){
      var params = {
        typeFilter : 'Individual',
        searchQuery : this.filters.searchQuery,
        quarterFilter : this.filters.quarterFilter,
        statusFilter : this.filters.statusFilter,
        yearFilter : this.filters.yearFilter,
        currentPage : this.filters.currentPage,
        per_page : this.per_page,
        teamFilter : this.filters.teamFilter.label,
        reviewStatusFilter : this.filters.reviewStatusFilter,
        employeeFilter: this.filters.employeeFilter,
      }
      axios
      .post("objectives/review-okrs", { params: params })
      .then(response => {
          this.items = response.data;
      })
      .catch((error) => {
          console.log(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Unable to fetch Review OKRs",
              text: error.message,
              icon: "BellIcon",
              variant: "danger",
            },
          });
        });
    }
  },
  computed: {
    propList() {
      return Object.keys(this.props).map(item => ({
        name: item,
      }));
    },
    filteredManagerOptions(){
      let filteredData = this.managerOptions.filter(item =>
        item.name.toLowerCase().includes(this.searchManager.toLowerCase()) || item.email.toLowerCase().includes(this.searchManager.toLowerCase())
      );
      return filteredData;
    },
    filteredEmployeeOptions(){
      let filteredData = this.employeeOptions.filter(item =>
        item.name.toLowerCase().includes(this.searchEmployee.toLowerCase()) || item.email.toLowerCase().includes(this.searchEmployee.toLowerCase())
      );
      return filteredData;
    },
    getClass(){
      if(this.pageType == 'all-okrs'){
        if(this.filters.typeFilter == 'Individual'){
          return 'individual-okrtable';
        }
        if(this.filters.typeFilter == 'Team'){
          return 'team-okrtable';
        }
        if(this.filters.typeFilter == 'Organisation'){
          return 'org-okrtable organisation-okr';
        }
      }
      return 'org-okrtable';
    }

  },

};

</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>